@use '@shared/util-styling/tokens';
@use '@shared/util-styling/mixins';

$_input-spacing-with-icon: calc(
  #{tokens.$mdrt-input-padding-horizontal} + #{tokens.$mdrt-input-size-icon} + #{tokens.$mdrt-input-gap-icon}
);

.form-textarea {
  @include mixins.input(1px);

  height: 112px;
  line-height: var(--input-line-height);
  resize: none;

  ~ label {
    background-color: var(--temp-color-neutral-light);
    right: var(--mdrt-spacing-12);
  }
}

.textarea-max-length {
  color: var(--mdrt-color-text-on-light-tertiary);
  font-size: var(--font-size-xs);
  padding: 0 var(--mdrt-spacing-08) 0 var(--mdrt-spacing-12);
}
