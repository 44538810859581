@use '@shared/util-styling/mixins';

.photo-section {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-16);

  @include mixins.breakpoint('medium') {
    align-items: center;
    flex-direction: row;
  }

  &__placeholder {
    align-items: center;
    background-color: var(--mdrt-color-backgrounds-bg-surface-03);
    border-radius: var(--mdrt-corner-radius-maximum);
    display: flex;
    height: 112px;
    justify-content: center;
    overflow: hidden;
    width: 112px;
  }

  &__photo {
    display: block;
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  &__icon {
    color: var(--mdrt-color-text-on-light-placeholder);
    height: 32px;
  }

  &__group {
    align-items: center;
    display: flex;
    gap: var(--spacing-8);
  }

  &__description {
    color: var(--mdrt-color-text-on-light-tertiary);
    font: var(--mdrt-body-4);
  }
}
