@use '@shared/util-styling/mixins';

@mixin tab-style {
  border-radius: var(--mdrt-corner-radius-s);
  color: var(--mdrt-color-text-on-light-primary);
  font: var(--mdrt-body-3-long);
  padding: var(--spacing-12);
  width: 100%;
}

$_icon-width: 20px;
$_caret-transition: transform 0.05s ease-in-out;
$_navigation-tabs-content-max-width: 660px;
$_navigation-tabs-list-width: 258px;
$_gap-desktop: 72px;

.navigation-tabs-wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-24);
  margin-bottom: var(--spacing-56);
  margin-top: var(--spacing-32);

  @include mixins.breakpoint('medium') {
    flex-direction: row;
    gap: $_gap-desktop;
  }
}

.navigation-tabs-content {
  flex-grow: 1;
  max-width: $_navigation-tabs-content-max-width;
}

.navigation-tabs-container {
  $_this: &;
  $_border-style: var(--mdrt-border-thickness-01) solid var(--mdrt-color-borders-dark-02);

  display: flex;
  flex-direction: column;
  gap: var(--spacing-4);

  &__tabs-list {
    display: none;
    flex-direction: column;
    gap: var(--spacing-4);

    @include mixins.breakpoint('medium') {
      display: flex;
      width: $_navigation-tabs-list-width;
    }
  }

  &__expand-button {
    @include mixins.button-reset;
    @include tab-style;

    align-items: center;
    background: var(--mdrt-color-backgrounds-bg-primary-selected);
    display: flex;
    gap: var(--spacing-8);

    @include mixins.breakpoint('medium') {
      display: none;

      &--skeleton {
        display: none;
      }
    }
  }

  &__expand-button-caret {
    transform: rotate(-90deg);
    transition: $_caret-transition;
    width: $_icon-width;
  }

  &--expanded {
    border-bottom: $_border-style;
    padding-bottom: var(--spacing-4);

    #{$_this}__expand-button-caret {
      transform: rotate(0deg);
    }

    #{$_this}__tabs-list {
      display: flex;
    }

    @include mixins.breakpoint('medium') {
      border-bottom: none;
      padding-bottom: 0;
    }
  }
}

.navigation-tab {
  $_hover-transition: background-color 0.1s ease;

  @include mixins.link-reset;
  @include tab-style;

  display: flex;
  justify-content: space-between;
  transition: $_hover-transition;

  &__check-icon {
    width: $_icon-width;

    @include mixins.breakpoint('medium') {
      display: none;
    }
  }

  &:hover {
    background: var(--mdrt-color-backgrounds-bg-neutral-hover);
  }

  &--active {
    background: var(--mdrt-color-backgrounds-bg-neutral-fill);
    pointer-events: none;

    @include mixins.breakpoint('medium') {
      background: var(--mdrt-color-backgrounds-bg-primary-selected);
    }
  }

  &:focus {
    outline: none;
  }

  &--focus-visible {
    &:focus {
      outline: auto;
    }
  }
}
