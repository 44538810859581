@use '@shared/util-styling/mixins';

.scrollable-container {
  @include mixins.overflow-extension(var(--spacing-8));

  -ms-overflow-style: none;
  overflow-x: auto;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}
