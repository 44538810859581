.main-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;

  &__footer-wrapper {
    border-top: 1px solid var(--mdrt-color-borders-dark-02);
    width: 100%;
  }
}
