@use 'modules/mdrt-org/shared/src/lib/styles/mixins';

.login-password-form {
  @include mixins.form-layout($footer-border: false);

  &__reset-password-button {
    width: fit-content;
  }
}

.biometric-switch {
  margin-top: var(--spacing-24);
}
