@use '@shared/util-styling/mixins';

.not-found-content {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  margin: 0 auto;
  max-width: 600px;
  padding: var(--spacing-24);

  &__icon {
    color: var(--mdrt-color-text-on-light-tertiary);
    height: 40px;
    width: 40px;
  }

  &__message {
    color: var(--mdrt-color-text-on-light-primary);
    font: var(--mdrt-title-2);
    margin-top: var(--spacing-24);
    text-align: center;
  }

  &__link {
    @include mixins.button;

    margin-top: var(--spacing-24);
  }
}
