@use '@shared/util-styling/mixins';

.modal {
  $_icon-size: 124px;
  $_modal-height-mobile: 100vh;
  $_modal-width-mobile: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  text-align: center;
  background: #fff;
  border-radius: var(--mdrt-corner-radius-m);
  width: $_modal-width-mobile;
  height: $_modal-height-mobile;
  padding: var(--spacing-32) var(--spacing-24) var(--spacing-24);
  overflow: auto;

  &__content-wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-8);
  }

  &__header {
    font: var(--mdrt-title-2);
    color: var(--mdrt-color-text-on-light-primary);
  }

  &__content {
    font: var(--mdrt-body-3-long);
    color: var(--mdrt-color-text-on-light-secondary);
  }

  &__icon {
    width: $_icon-size;
    height: $_icon-size;
  }

  &__footer {
    width: 100%;
    display: flex;
    gap: var(--spacing-12);
    justify-content: center;

    > * {
      width: 140px;
    }
  }

  @include mixins.breakpoint('medium') {
    width: 340px;
    height: fit-content;
  }

  @include mixins.breakpoint('large') {
    width: 340px;
    height: fit-content;

    &__footer {
      margin-top: auto;
    }
  }
}
