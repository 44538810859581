@use '@shared/util-styling/tokens';

.field {
  position: relative;

  &__label {
    color: tokens.$mdrt-input-text-label;
    font: var(--mdrt-body-3-short);
    left: tokens.$mdrt-input-padding-horizontal;
    line-height: 1;
    padding-top: calc(#{tokens.$mdrt-input-padding-vertical} - #{tokens.$mdrt-input-gap-label});
    pointer-events: none;
    position: absolute;
    top: calc(#{tokens.$mdrt-input-padding-vertical} + #{tokens.$mdrt-input-gap-label});
    transition: all 0.1s ease-in;
  }

  &__error-icon {
    color: var(--field-error-message-color);
    height: tokens.$mdrt-input-size-icon;
    pointer-events: none;
    position: absolute;
    right: tokens.$mdrt-input-padding-horizontal;
    top: tokens.$mdrt-input-padding-horizontal;
    width: tokens.$mdrt-input-size-icon;
  }

  &__hint {
    color: var(--mdrt-color-text-on-light-tertiary);
    font: var(--mdrt-title-4);
    margin-top: var(--spacing-2);
    padding-inline: var(--spacing-12);
  }
}
