@use '@shared/util-styling/tokens';
@use '@shared/util-styling/mixins';

.alert {
  color: var(--mdrt-color-text-on-light-tertiary); // TODO add token when it will be available
  display: flex;
  gap: tokens.$mdrt-alerts-gap-between;
  padding: tokens.$mdrt-alerts-padding;

  &__message {
    font: var(--alert-font-style);
  }

  &__icon {
    display: block;
    width: tokens.$mdrt-alerts-icon-size;
  }

  &__close-button {
    @include mixins.button-reset;

    color: var(--mdrt-color-text-on-light-secondary);
    height: var(--mdrt-size-m);
    width: var(--mdrt-size-m);
  }

  &__close-button-wrapper {
    align-items: center;
    display: flex;
    margin-left: auto;
    position: relative;
    width: var(--spacing-24);
  }

  &__button-icon {
    height: var(--mdrt-size-m);
    width: var(--mdrt-size-m);
  }

  &__variant {
    border-radius: tokens.$mdrt-alerts-corner-radius;
    padding: tokens.$mdrt-alerts-padding;

    &--error {
      background-color: tokens.$mdrt-alerts-bg-fill-error;
      color: tokens.$mdrt-alerts-text-error;
    }

    &--informational {
      background-color: tokens.$mdrt-alerts-bg-fill-informational;
      color: tokens.$mdrt-alerts-text-informational;
    }

    &--success {
      background-color: tokens.$mdrt-alerts-bg-fill-success;
      color: tokens.$mdrt-alerts-text-success;
    }
  }
}
