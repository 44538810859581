.modal-trigger {
  z-index: var(--z-index-navbar);

  &__actions {
    background-color: var(--mdrt-color-backgrounds-bg-loader);
    border-radius: var(--mdrt-corner-radius-m);
    display: flex;
    position: fixed;
    right: var(--spacing-16);
    top: var(--spacing-16);

    &--is-theme-secondary {
      background-color: var(--temp-color-primary-fill-80);
    }
  }

  &__button {
    height: var(--mobile-drawer-actions-height);
    width: var(--mobile-drawer-actions-width);

    svg {
      // NOTE: leave pointer-events: none because google analytics needs to track <button /> tag attributes but not the <svg />
      pointer-events: none;
    }
  }
}
