@use '@shared/util-styling/tokens';
@use '@shared/util-styling/mixins';

.form-input {
  @include mixins.input;
  @include mixins.ellipsis;

  appearance: none;
  line-height: var(--input-line-height-2);

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }

  &:read-only {
    background-color: tokens.$mdrt-input-bg-read-only;
    border-color: tokens.$mdrt-input-border-read-only;
    color: tokens.$mdrt-input-text-read-only;
  }

  &:focus,
  &--has-value {
    line-height: var(--input-line-height);
  }
}
