@use '@shared/util-styling/mixins';

.error-content {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 0 auto;
  max-width: 600px;
  padding: var(--spacing-24);

  &__icon {
    color: var(--mdrt-color-text-on-light-tertiary);
    height: 40px;
    width: 40px;
  }

  &__message {
    color: var(--mdrt-color-text-on-light-primary);
    font: var(--mdrt-title-2);
    margin-top: var(--spacing-24);
    text-align: center;
  }

  &__try-again-button {
    margin-top: var(--spacing-24);
  }

  &__actions {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-16);
    margin-top: var(--spacing-24);
    width: 100%;
  }

  @include mixins.breakpoint('small') {
    justify-content: center;

    &__actions {
      flex-direction: row;
      justify-content: center;
    }
  }
}
