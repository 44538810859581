/* stylelint-disable value-no-vendor-prefix */
@mixin line-clamp($lines: 1) {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  line-clamp: $lines;
  overflow: hidden;

  * {
    display: inline;
  }
}
