.carousel-skeleton {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--spacing-48);
  margin-left: var(--spacing-24);
  margin-top: var(--spacing-24);
  max-width: var(--content-max-width-wide);
  overflow-x: hidden;

  &__title-skeleton-wrapper {
    align-self: center;
    margin-bottom: var(--spacing-24);
    max-width: var(--content-max-width);
    width: 100%;
  }

  &__title-skeleton {
    align-self: flex-start;
    opacity: 0.5;
  }

  &__card-row {
    display: flex;
    overflow: hidden;
  }
}
