.loader {
  align-items: center;
  background-color: rgb(255 255 255 / 60%);
  color: var(--mdrt-color-text-on-light-action);
  display: flex;
  flex-direction: column;
  inset: 0;
  justify-content: center;
  position: fixed;
  z-index: var(--z-index-loader-overlay);

  &:focus {
    outline: none;
  }

  &--with-text {
    background-color: rgb(255 255 255 / 90%);
  }

  &__logo-container {
    position: relative;

    &--centered {
      position: fixed;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__text-container {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-24);
    margin: var(--spacing-40) var(--spacing-16) var(--spacing-8);
    max-width: 450px;
    text-align: center;

    h2,
    h3 {
      margin: 0;
    }
  }

  &--is-inner {
    border-radius: inherit;
    position: absolute;
    z-index: var(--z-index-inner-loader-overlay);
  }
}
