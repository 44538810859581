@use '@shared/util-styling/mixins';
@use '@shared/util-styling/tokens';

.prompt-wrapper {
  left: 50%;
  max-width: 700px;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(-50%);
  width: 100%;
  z-index: var(--z-index-pwa-prompt, 9998);
}

.prompt-wrapper .prompt {
  $_prompt-shadow: 0 0 25px -10px #00000073;

  align-items: center;
  background-color: var(--temp-color-neutral-light);
  box-shadow: $_prompt-shadow;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-16);
  margin: 0 auto;
  padding: var(--spacing-16);
  position: relative;

  @include mixins.breakpoint('small') {
    flex-direction: row;
    max-width: 700px;
  }

  &__message {
    font-size: var(--mdrt-font-size-2);
  }

  &__install-button {
    font-size: var(--mdrt-font-size-1);
    margin-inline: auto var(--spacing-32);
    padding: var(--spacing-12);
    width: 100%;

    @include mixins.breakpoint('small') {
      padding: tokens.$mdrt-buttons-vertical-padding tokens.$mdrt-buttons-horizontal-padding;
      width: fit-content;
    }
  }

  &__close-button {
    background-color: inherit;
    border: 0;
    flex-shrink: 0;
    padding: var(--spacing-4);
    position: absolute;
    right: var(--spacing-8);
    top: var(--spacing-8);

    svg {
      width: 16px;
    }
  }
}
