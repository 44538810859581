@use '@shared/util-styling/mixins';

.pagination {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  font-size: var(--font-size-s);
  gap: var(--spacing-16);
  justify-content: center;
  padding: var(--spacing-10) 0;
  width: 100%;

  &__item-range {
    display: inline-block;
    font-weight: var(--font-weight-medium);
    margin-right: var(--spacing-8);
  }
}

.button-wrapper {
  @include mixins.button-group-wrapper;
}

.button {
  @include mixins.button;

  height: 24px;
  padding: 0;
  width: 24px;

  &__icon-next {
    transform: rotate(180deg);
  }
}
