$_breakpoint-medium: 620px;
$_breakpoint-large: 820px;

@function generate-box-shadow($color, $width, $count) {
  $shadows: '';

  @for $i from 1 through $count {
    $shadows: #{$shadows}, #{($width * $i)}px 0 0 $color;
  }

  @return $shadows;
}

.slider-container {
  $_container-indent: 20px;
  $_container-indent-large: 30px;

  align-items: center;
  display: flex;
  height: 80px;
  left: $_container-indent;
  position: relative;
  width: 270px;

  @media (min-width: $_breakpoint-medium) {
    left: $_container-indent;
    width: 440px;
  }

  @media (min-width: $_breakpoint-large) {
    left: $_container-indent-large;
    width: 620px;
  }
}

.slider {
  $_background-color: var(--mdrt-color-backgrounds-bg-surface);
  $_thumb-width-mobile: 40px;
  $_thumb-width: 58px;
  $_thumb-transition: height 0.1s ease-out;
  $_thumb-height: 40px;
  $_thumb-height-dragging: 34px;
  $_thumb-z-index: 1;
  $_track-separator-width: 4px;
  $_track-height: 6px;

  width: 100%;

  &__track-style {
    cursor: pointer;
    height: $_track-height;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 310px;

    &::after {
      box-shadow: $_thumb-width-mobile 0 0 $_background-color, 67px 0 0 $_background-color,
        94px 0 0 $_background-color, 121px 0 0 $_background-color, 148px 0 0 $_background-color,
        175px 0 0 $_background-color, 202px 0 0 $_background-color, 229px 0 0 $_background-color,
        256px 0 0 $_background-color, 283px 0 0 $_background-color;
      content: '';
      display: block;
      height: 100%;
      position: absolute;
      top: 0;
      width: $_track-separator-width;
    }

    &--1 {
      &::after {
        box-shadow: 23px 0 0 $_background-color, 67px 0 0 $_background-color,
          94px 0 0 $_background-color, 121px 0 0 $_background-color, 148px 0 0 $_background-color,
          175px 0 0 $_background-color, 202px 0 0 $_background-color, 229px 0 0 $_background-color,
          256px 0 0 $_background-color, 283px 0 0 $_background-color;
      }
    }

    &--2 {
      &::after {
        box-shadow: 23px 0 0 $_background-color, 50px 0 0 $_background-color,
          94px 0 0 $_background-color, 121px 0 0 $_background-color, 148px 0 0 $_background-color,
          175px 0 0 $_background-color, 202px 0 0 $_background-color, 229px 0 0 $_background-color,
          256px 0 0 $_background-color, 283px 0 0 $_background-color;
      }
    }

    &--3 {
      &::after {
        box-shadow: 23px 0 0 $_background-color, 50px 0 0 $_background-color,
          77px 0 0 $_background-color, 121px 0 0 $_background-color, 148px 0 0 $_background-color,
          175px 0 0 $_background-color, 202px 0 0 $_background-color, 229px 0 0 $_background-color,
          256px 0 0 $_background-color, 283px 0 0 $_background-color;
      }
    }

    &--4 {
      &::after {
        box-shadow: 23px 0 0 $_background-color, 50px 0 0 $_background-color,
          77px 0 0 $_background-color, 104px 0 0 $_background-color, 148px 0 0 $_background-color,
          175px 0 0 $_background-color, 202px 0 0 $_background-color, 229px 0 0 $_background-color,
          256px 0 0 $_background-color, 283px 0 0 $_background-color;
      }
    }

    &--5 {
      &::after {
        box-shadow: 23px 0 0 $_background-color, 50px 0 0 $_background-color,
          77px 0 0 $_background-color, 104px 0 0 $_background-color, 131px 0 0 $_background-color,
          175px 0 0 $_background-color, 202px 0 0 $_background-color, 229px 0 0 $_background-color,
          256px 0 0 $_background-color, 283px 0 0 $_background-color;
      }
    }

    &--6 {
      &::after {
        box-shadow: 23px 0 0 $_background-color, 50px 0 0 $_background-color,
          77px 0 0 $_background-color, 104px 0 0 $_background-color, 131px 0 0 $_background-color,
          158px 0 0 $_background-color, 202px 0 0 $_background-color, 229px 0 0 $_background-color,
          256px 0 0 $_background-color, 283px 0 0 $_background-color;
      }
    }

    &--7 {
      &::after {
        box-shadow: 23px 0 0 $_background-color, 50px 0 0 $_background-color,
          77px 0 0 $_background-color, 104px 0 0 $_background-color, 131px 0 0 $_background-color,
          158px 0 0 $_background-color, 185px 0 0 $_background-color, 229px 0 0 $_background-color,
          256px 0 0 $_background-color, 283px 0 0 $_background-color;
      }
    }

    &--8 {
      &::after {
        box-shadow: 23px 0 0 $_background-color, 50px 0 0 $_background-color,
          77px 0 0 $_background-color, 104px 0 0 $_background-color, 131px 0 0 $_background-color,
          158px 0 0 $_background-color, 185px 0 0 $_background-color, 212px 0 0 $_background-color,
          256px 0 0 $_background-color, 283px 0 0 $_background-color;
      }
    }

    &--9 {
      &::after {
        box-shadow: 23px 0 0 $_background-color, 50px 0 0 $_background-color,
          77px 0 0 $_background-color, 104px 0 0 $_background-color, 131px 0 0 $_background-color,
          158px 0 0 $_background-color, 185px 0 0 $_background-color, 212px 0 0 $_background-color,
          239px 0 0 $_background-color, 283px 0 0 $_background-color;
      }
    }

    &--10 {
      &::after {
        box-shadow: 23px 0 0 $_background-color, 50px 0 0 $_background-color,
          77px 0 0 $_background-color, 104px 0 0 $_background-color, 131px 0 0 $_background-color,
          158px 0 0 $_background-color, 185px 0 0 $_background-color, 212px 0 0 $_background-color,
          239px 0 0 $_background-color, 266px 0 0 $_background-color;
      }
    }
  }

  &__thumb {
    align-items: center;
    border-radius: var(--mdrt-corner-radius-m);
    cursor: pointer;
    display: flex;
    height: $_thumb-height;
    justify-content: center;
    overflow: hidden;
    position: relative;
    top: 2px;
    transition: $_thumb-transition;
    width: $_thumb-width-mobile;
    z-index: $_thumb-z-index;

    &--is-dragging {
      height: $_thumb-height-dragging;
    }

    &--is-focused {
      outline: auto;
    }
  }

  &__output {
    font: var(--mdrt-title-2);
    pointer-events: none;

    &--light {
      color: $_background-color;
    }
  }

  @media (min-width: $-breakpoint-medium) {
    &__track-style {
      width: 480px;

      &::after {
        background: $_background-color;
        box-shadow: generate-box-shadow($_background-color, 44, 9);
        left: $_thumb-width-mobile;
      }
    }

    &__thumb {
      width: $_thumb-width-mobile;
    }
  }

  @media (min-width: $_breakpoint-large) {
    &__track-style {
      width: 678px;

      &::after {
        box-shadow: generate-box-shadow($_background-color, 62, 9);
        left: $_thumb-width;
      }
    }

    &__thumb {
      width: $_thumb-width;
    }
  }
}
