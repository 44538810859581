@use '@shared/util-styling/mixins';

.footer {
  @mixin footer-link-base {
    color: var(--mdrt-color-text-on-light-action);
    text-decoration: none;

    &:hover {
      color: var(--mdrt-color-text-on-light-action);
      text-decoration: none;
    }
  }

  color: var(--mdrt-color-text-on-light-action);
  margin-top: auto;
  width: 100%;

  &__content {
    border-bottom: 1px solid var(--mdrt-color-borders-dark-02);
    display: grid;
    grid-template: repeat(4, auto) / auto;
    margin-bottom: var(--spacing-32);
    margin-top: var(--spacing-40);
  }

  &__logo {
    color: var(--mdrt-color-text-on-light-action);
    height: 68px;
  }

  &__heading {
    color: var(--temp-color-neutral-dark);
    font: var(--mdrt-title-4);
    margin: 0;
  }

  &__contacts {
    display: grid;
    gap: var(--spacing-24);
    justify-content: flex-start;
  }

  &__address {
    color: var(--temp-color-neutral-dark);
    margin: 0;
  }

  &__links-grid,
  &__badges {
    display: grid;
    gap: var(--spacing-24);
    margin-top: var(--spacing-24);
    width: fit-content;
  }

  &__links-grid-item {
    @include mixins.button-reset;

    align-items: center;
    color: var(--mdrt-color-text-on-light-action);
    display: inline-flex;
    font-size: 2rem;
    font-weight: 600;
    max-width: 200px;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  &__links-grid-item-icon {
    height: 20px;
    margin-left: var(--spacing-8);
    position: relative;
    top: 4px;
    width: 20px;
  }

  &__contacts,
  &__chapter-sites,
  &__helpful-links,
  &__mdrt-apps {
    margin-bottom: var(--spacing-32);
  }

  &__contacts-link {
    @include footer-link-base;

    font-size: 2rem;
    font-weight: 600;
  }

  &__badge-google-play {
    margin: -13px;
  }

  &__badge-app-store,
  &__badge-google-play {
    // NOTE: leave pointer-events: none because google analytics needs to track <a /> tag attributes but not the <img />
    img {
      pointer-events: none;
    }
  }

  &__bottom-container {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-16);
    margin-bottom: var(--spacing-40);
  }

  &__copyright {
    color: var(--temp-color-neutral-dark);
    font: var(--mdrt-title-3);
  }

  &__copyright-heading {
    margin-bottom: var(--spacing-8);
  }

  &__copyright-links {
    display: grid;
    gap: var(--spacing-8);
  }

  &__copyright-links-item {
    @include footer-link-base;
  }

  @media print {
    display: none;
  }
}

@include mixins.breakpoint('small') {
  .footer {
    &__content {
      grid-template-columns: repeat(2, auto);
    }

    &__contacts {
      grid-row-start: 2;
    }

    &__helpful-links,
    &__contacts {
      padding-right: var(--spacing-20);
    }
  }
}

@include mixins.breakpoint('medium') {
  .footer {
    &__content {
      grid-template-columns: repeat(4, auto);
      padding-bottom: var(--spacing-40);
    }

    &__contacts,
    &__chapter-sites,
    &__helpful-links,
    &__mdrt-apps {
      border: none;
      margin: 0;
      padding-bottom: 0;
      padding-right: var(--spacing-8);
      padding-top: var(--spacing-40);
    }

    &__contacts {
      grid-row-start: initial;
    }

    &__bottom-container {
      flex-direction: row;
      gap: var(--spacing-40);
      justify-content: space-between;
      margin-top: var(--spacing-40);
    }

    &__copyright {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      gap: var(--spacing-8);
    }

    &__copyright-heading {
      margin-bottom: 0;
      margin-right: var(--spacing-32);
    }

    &__copyright-links {
      gap: var(--spacing-40);
      grid-template-columns: repeat(2, auto);
    }
  }
}

.social-links-grid {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-20);

  @include mixins.breakpoint('medium') {
    flex-wrap: nowrap;
  }

  a:hover {
    opacity: 0.6;
  }

  svg {
    color: var(--mdrt-color-text-on-light-action);

    // NOTE: leave pointer-events: none because google analytics needs to track <a /> tag attributes but not the <svg />
    pointer-events: none;
    width: 32px;
  }
}
