@use 'modules/mdrt-org/shared/src/lib/styles/mixins';
@use '@shared/util-styling/mixins' as global-mixins;

.professional-info-form {
  @include mixins.form-layout;
}

.confirmation-modal {
  @include global-mixins.modal-size-small;

  &__content {
    p + p {
      margin-top: var(--spacing-8);
    }
  }
}
