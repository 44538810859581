@use '@shared/util-styling/tokens';
@use '@shared/util-styling/mixins';

// TODO add missing tokens.

.toast {
  $_toast-fade-in-animation: fadein 0.2s forwards;
  $_toast-fade-out-animation: fadeout 0.2s forwards;

  animation: $_toast-fade-in-animation;
  background-color: var(--mdrt-color-backgrounds-bg-surface-03);
  border-radius: tokens.$mdrt-alerts-corner-radius;
  color: var(--mdrt-color-text-on-light-secondary);
  display: flex;
  gap: tokens.$mdrt-alerts-gap-between;
  max-width: var(--toast-max-width);
  padding: tokens.$mdrt-alerts-padding;
  width: calc(100% - 2 * var(--spacing-24));

  &--without-close-button {
    width: auto;
  }

  &--error {
    background-color: tokens.$mdrt-alerts-bg-fill-error;
    color: tokens.$mdrt-alerts-text-error;
  }

  &--info {
    background-color: tokens.$mdrt-alerts-bg-fill-informational;
    color: tokens.$mdrt-alerts-text-informational;
  }

  &--success {
    background-color: tokens.$mdrt-alerts-bg-fill-success;
    color: tokens.$mdrt-alerts-text-success;
  }

  &[data-animation='exiting'] {
    animation: $_toast-fade-out-animation;
  }

  &__icon {
    display: block;
    flex-shrink: 0;
    height: var(--mdrt-size-m);
    width: var(--mdrt-size-m);
  }

  &__message {
    display: block;
    flex: 1;
    font: var(--alert-font-style);
    line-height: var(--mdrt-line-heights-4);
  }

  &__actions-container {
    align-items: center;
    display: flex;
  }

  &__separator {
    background-color: var(--mdrt-color-borders-dark-02);
    margin: 0 var(--spacing-8) 0 var(--spacing-4);
    min-height: 100%;
    width: 1px;
  }

  &__close-button {
    @include mixins.button-reset;

    color: var(--mdrt-color-text-on-light-tertiary);
    height: var(--mdrt-size-m);
    width: var(--mdrt-size-m);
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
