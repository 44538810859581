@use '@shared/util-styling/tokens';
@use '@shared/util-styling/mixins';

.tags {
  $this: &;

  :global(.react-aria-TagList) {
    display: flex;
    flex-wrap: wrap;
    gap: var(--spacing-12);
  }

  &--center-content {
    text-align: center;

    :global(.react-aria-TagList) {
      gap: var(--spacing-8);
      justify-content: center;
    }
  }

  &__label {
    font: var(--mdrt-headline-4);
  }

  &__description {
    color: var(--mdrt-color-text-on-light-secondary);
    font: var(--mdrt-title-3);
    margin-bottom: var(--spacing-16);
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    gap: var(--spacing-12);
  }

  &__error-message {
    margin-bottom: var(--spacing-24);
  }
}

.tag {
  @include mixins.chip;

  &__icon-container {
    @include mixins.button-reset;
  }
}
