@use '@shared/util-styling/mixins';

$overlay-color-legacy: rgb(0 0 0 / 80%);
$overlay-color-modern-light: rgb(10 10 10 / 50%);
$overlay-color-modern-dark: rgb(10 10 10 / 50%);
$bg-color-legacy: rgb(250 250 250 / 80%);
$bg-color-modern-light: rgb(255 255 255 / 60%);
$bg-color-modern-dark: rgb(65 65 65 / 70%);
$border-color-legacy: rgb(0 0 0 / 10%);
$border-color-modern-light: rgb(60 60 67 / 29%);
$border-color-modern-dark: rgb(140 140 140 / 70%);
$font-family: -apple-system, system-ui, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica Neue',
  'Arial', sans-serif;
$title-color-legacy: rgb(51 51 51);
$title-color-modern-light: rgb(0 0 0 / 100%);
$title-color-modern-dark: rgb(255 255 255 / 100%);
$font-color-legacy: rgb(123 123 122);
$font-color-modern-light: rgb(60 60 67 / 60%);
$font-color-modern-dark: rgb(235 235 245 / 60%);
$blue-color-legacy: rgb(45 124 246);
$blue-color-modern-light: rgb(0 85 179 / 100%);
$blue-color-modern-dark: rgb(9 132 255 / 100%);

.overlay {
  background-color: $overlay-color-legacy;
  left: 0;
  min-height: 100%;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: opacity 0.2s ease-in;
  width: 100%;
  z-index: var(--z-index-pwa-prompt, 9998);

  &.is-visible {
    display: block;
    opacity: 1;
  }

  &.is-hidden {
    pointer-events: none;
    touch-action: none;
  }

  &.is-modern {
    @media (prefers-color-scheme: dark) {
      & {
        background: $overlay-color-modern-dark;
        color: $font-color-modern-dark;
      }
    }
  }
}

.prompt {
  backdrop-filter: blur(10px);
  background-color: $bg-color-legacy;
  border-radius: 10px;
  bottom: 0;
  color: #000;
  filter: brightness(1.1);
  font-family: $font-family;
  left: 0;
  margin: 0 var(--spacing-8) var(--spacing-10);
  overflow: hidden;
  position: fixed;
  right: 0;
  transform: translateY(calc(100% + 10px));
  transition: transform 0.4s cubic-bezier(0.4, 0.24, 0.3, 1);
  width: calc(100vw - #{var(--spacing-16)});
  z-index: 999999;

  @include mixins.breakpoint('medium') {
    margin-inline: auto;
    max-width: 590px;
  }

  &.is-visible {
    display: block;
    transform: translateY(0);
  }

  &.is-hidden {
    pointer-events: none;
    touch-action: none;
  }

  &.is-modern {
    background: $bg-color-modern-light;
    filter: brightness(1.6);

    @media (prefers-color-scheme: dark) {
      & {
        background: $bg-color-modern-dark;
        filter: brightness(1.1);
      }
    }
  }
}

.header {
  align-items: center;
  border-bottom: 1px solid $border-color-legacy;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding: var(--spacing-12) var(--spacing-16);

  .is-modern & {
    border-color: $border-color-modern-light;

    @media (prefers-color-scheme: dark) {
      & {
        border-color: $border-color-modern-dark;
      }
    }
  }
}

.title {
  color: $title-color-legacy;
  font-family: $font-family;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.125;
  margin: 0;
  padding: 0;

  .is-modern & {
    color: $title-color-modern-light;

    @media (prefers-color-scheme: dark) {
      & {
        color: $title-color-modern-dark;
      }
    }
  }
}

.cancel-btn {
  background: transparent;
  border: 0;
  color: $blue-color-legacy;
  font-size: 16px;
  margin: 0;
  padding: 0;

  .is-modern & {
    color: $blue-color-modern-light;

    @media (prefers-color-scheme: dark) {
      & {
        color: $blue-color-modern-dark;
      }
    }
  }
}

.body {
  display: flex;
  width: 100%;
}

.description {
  border-bottom: 1px solid $border-color-legacy;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  color: inherit;
  margin: 0 var(--spacing-16);
  padding: var(--spacing-16);
  width: 100%;

  .is-modern & {
    border-color: $border-color-modern-light;

    @media (prefers-color-scheme: dark) {
      & {
        border-color: $border-color-modern-dark;
      }
    }
  }
}

.paragraph {
  color: $font-color-legacy;
  font-family: $font-family;
  font-size: 13px;
  line-height: 17px;
  margin: 0;
  padding: 0;

  &--bold {
    font-weight: 600;
  }

  .is-modern & {
    color: $font-color-modern-light;

    @media (prefers-color-scheme: dark) {
      & {
        border-color: $font-color-modern-dark;
        color: $font-color-modern-dark;
      }
    }
  }
}

.instructions {
  color: inherit;
  margin: 0 var(--spacing-16);
  padding: var(--spacing-16);
}

.instruction-step {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  margin-bottom: var(--spacing-16);
  text-align: left;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.share-icon,
.home-icon {
  flex: 0 0 auto;
  height: 30px;
  margin-right: var(--spacing-32);
  width: 25px;
}

.home-icon {
  color: $blue-color-legacy;

  .is-modern & {
    color: #000;
    fill: #000;

    @media (prefers-color-scheme: dark) {
      & {
        color: #fff;
        fill: #fff;
      }
    }
  }

  .is-safari & {
    color: #494949;
    fill: #494949;

    @media (prefers-color-scheme: dark) {
      & {
        color: #f2f2f2;
        fill: #f2f2f2;
      }
    }
  }
}

.share-icon {
  color: $blue-color-legacy;
  fill: $blue-color-legacy;

  .is-modern & {
    color: $blue-color-modern-light;
    fill: $blue-color-modern-light;

    @media (prefers-color-scheme: dark) {
      & {
        color: $blue-color-modern-dark;
        fill: $blue-color-modern-dark;
      }
    }
  }

  .is-safari & {
    color: #717171;
    fill: #717171;

    @media (prefers-color-scheme: dark) {
      & {
        color: #b6b6b6;
        fill: #b6b6b6;
      }
    }
  }
}
