@use 'responsive';

@mixin modal-size-small {
  height: 100%;
  width: 100%;

  @include responsive.breakpoint('small') {
    max-height: 70vh;
    max-width: 470px;
  }
}
