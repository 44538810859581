$_strip-height: 4px;

.loader-strip-container {
  display: flex;
  height: $_strip-height;
}

.loader-strip {
  display: block;
  height: $_strip-height;
  width: 100%;
}
