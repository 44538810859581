@use '@shared/util-styling/mixins';
@use '@shared/util-styling/tokens';

@mixin focus-outline {
  &:has(:focus-visible) {
    outline: auto;
    outline-offset: 4px;
  }
}

@mixin checkbox-label {
  color: tokens.$mdrt-input-text-value;
  display: flex;
  gap: var(--mdrt-spacing-12);
}

.checkbox {
  width: fit-content;

  @include focus-outline;

  &__control {
    appearance: none;
    border: 2px solid var(--mdrt-color-text-on-light-primary);
    border-radius: var(--mdrt-corner-radius-xs);
    flex-shrink: 0;
    height: 18px;
    margin-top: var(--spacing-2);
    position: relative;
    width: 18px;

    @include mixins.transition {
      transition: all 0.2s ease-in-out;
    }

    &::after {
      background-color: var(--mdrt-color-backgrounds-bg-neutral-hover);
      border-radius: var(--mdrt-corner-radius-maximum);
      content: '';
      height: 40px;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%) scale(0);
      width: 40px;

      @include mixins.transition {
        transition: transform 0.2s ease-in-out;
      }
    }

    &:checked {
      background-color: var(--mdrt-color-text-on-light-action);
      border-color: var(--mdrt-color-text-on-light-action);

      &::before {
        border-bottom: 2px solid var(--mdrt-color-borders-light-01);
        border-right: 2px solid var(--mdrt-color-borders-light-01);
        content: '';
        height: 10px;
        left: 1px;
        position: absolute;
        top: -4px;
        transform: rotate(45deg);
        transform-origin: 0 100%;
        width: 4px;
      }
    }

    &:focus {
      outline: none;

      &::after {
        transform: translate(-50%, -50%) scale(1);
      }
    }

    &:disabled {
      border-color: var(--mdrt-color-text-on-light-disabled);

      &:checked {
        background-color: var(--mdrt-color-text-on-light-disabled);
      }
    }
  }

  &__label {
    font: var(--mdrt-body-3-long);
    padding: 9px 0 9px var(--mdrt-spacing-12);

    @include checkbox-label;
  }
}

.switch {
  $_switch-bubble-size: 20px;
  $_switch-bubble-spacing: 2px;

  width: fit-content;

  @include focus-outline;

  &__control {
    appearance: none;
    background-color: var(--mdrt-color-backgrounds-bg-surface-04);
    border-radius: var(--mdrt-corner-radius-l);
    cursor: pointer;
    flex-shrink: 0;
    height: 24px;
    position: relative;
    width: 40px;

    @include mixins.transition {
      transition: background-color 0.2s ease-in-out;
    }

    &::after {
      background-color: var(--mdrt-color-backgrounds-bg-surface);
      border-radius: var(--mdrt-corner-radius-maximum);
      content: '';
      height: $_switch-bubble-size;
      position: absolute;
      top: $_switch-bubble-spacing;
      transform: translateX($_switch-bubble-spacing);
      width: $_switch-bubble-size;

      @include mixins.transition {
        transition: transform 0.2s linear;
        will-change: transform;
      }
    }

    &:checked {
      background-color: var(--mdrt-color-backgrounds-bg-primary-fill-01);

      &::after {
        transform: translateX(calc($_switch-bubble-size - $_switch-bubble-spacing));
      }
    }

    &:focus {
      outline: none;
    }
  }

  &__label {
    font: var(--mdrt-body-3-long);
    justify-content: space-between;

    @include checkbox-label;
  }
}
