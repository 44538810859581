@use '@shared/util-styling/tokens';

@mixin input-focus-state {
  border-color: transparent;
  box-shadow: 0 0 0 tokens.$mdrt-input-border-thickness-focus tokens.$mdrt-input-border-focus;
  outline: 0;
}

@mixin input($label-top: 0) {
  background-color: tokens.$mdrt-input-bg-fill;
  border: 1px solid tokens.$mdrt-input-border-enabled;
  border-radius: tokens.$mdrt-input-border-radius;
  caret-color: tokens.$mdrt-input-bg-cursor;
  color: tokens.$mdrt-input-text-value;
  display: block;
  font-size: var(--input-font-size);
  font-weight: var(--input-font-weight);
  padding: tokens.$mdrt-input-padding-vertical tokens.$mdrt-input-padding-horizontal;
  transition: all 0.1s ease-in;
  width: 100%;

  &::placeholder {
    color: tokens.$mdrt-input-text-placeholder;
    opacity: 1;
  }

  &:hover {
    background-color: tokens.$mdrt-input-bg-hover;
    border-color: transparent;
    box-shadow: 0 0 0 tokens.$mdrt-input-border-thickness-hover tokens.$mdrt-input-border-hover;
  }

  &:focus {
    @include input-focus-state;
  }

  &--has-error {
    border-color: tokens.$mdrt-input-border-error;
    padding-right: calc(
      #{tokens.$mdrt-input-padding-horizontal} + #{tokens.$mdrt-input-size-icon} + #{tokens.$mdrt-input-gap-icon}
    );

    ~ label {
      color: tokens.$mdrt-input-text-label-error;
    }

    &:focus,
    &:hover {
      box-shadow: 0 0 0 tokens.$mdrt-input-border-thickness-focus tokens.$mdrt-input-border-error;
    }
  }

  &--has-value,
  &:focus {
    padding-bottom: calc(#{tokens.$mdrt-input-padding-vertical} - #{tokens.$mdrt-input-gap-label});
    padding-top: calc(#{var(--spacing-16)} + #{tokens.$mdrt-input-gap-label});

    ~ label {
      font-size: var(--font-size-2xs);
      font-weight: var(--font-weight-medium);
      top: $label-top;
    }
  }

  &:disabled {
    background-color: tokens.$mdrt-input-bg-disabled;
    border-color: tokens.$mdrt-input-border-disabled;
    color: tokens.$mdrt-input-text-disabled;

    &:hover {
      box-shadow: none;
    }
  }
}
