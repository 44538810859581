@use '@shared/util-styling/mixins';
@use '@shared/util-styling/tokens';

$_navigation-tabs-content-max-width: 660px;
$_navigation-tabs-list-width: 258px;
$_gap-desktop: 72px;
$_skeleton-size: 220px;

.tab-panel-skeleton {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: $_navigation-tabs-content-max-width;

  &__heading {
    height: 32px;
    margin-bottom: var(--spacing-24);
    max-width: $_skeleton-size;
  }

  &__text {
    height: 48px;
    margin-bottom: var(--spacing-24);
    width: 100%;
  }

  &__links {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-24);
    margin-bottom: var(--spacing-24);
  }

  &__link {
    height: 32px;
    width: $_skeleton-size;
  }

  &__button-container {
    display: flex;
    justify-content: flex-end;
  }

  &__button {
    border-radius: tokens.$mdrt-icon-button-corner-radius;
    height: 40px;
    margin-left: auto;
    width: 180px;
  }

  @include mixins.breakpoint('small') {
    &__links {
      flex-direction: row;
    }
  }
}

.tabs-navigation-skeleton {
  &__menu-button,
  &__skeleton-item {
    height: 47px;
  }

  &__list {
    display: none;
  }

  @include mixins.breakpoint('medium') {
    &__list {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-4);
      width: $_navigation-tabs-list-width;
    }

    &__menu-button-container {
      display: none;
    }
  }
}

.hash-tabs-navigation-skeleton {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-24);
  margin-bottom: var(--spacing-56);
  margin-top: var(--spacing-32);

  @include mixins.breakpoint('medium') {
    flex-direction: row;
    gap: $_gap-desktop;
  }
}
