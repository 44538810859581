.logo {
  $_size: 48px;

  background-color: var(--temp-color-primary-tint-darker);
  border-radius: var(--mdrt-corner-radius-s);
  color: var(--temp-color-neutral-light);
  flex-shrink: 0;
  height: $_size;
  padding: var(--spacing-8);
  width: $_size;
}
