@use '@shared/util-styling/mixins';

.phone-section {
  $spacing-size: var(--spacing-24);

  display: flex;
  flex-direction: column;
  gap: $spacing-size;

  &__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: var(--spacing-8);
  }

  &__header-title {
    font: var(--mdrt-title-3);
  }

  &__phone-type-input-container {
    margin-bottom: $spacing-size;
  }

  &__input-container {
    flex-grow: 1;
    width: 100%;
  }

  &__remove-button {
    @include mixins.button-reset;

    color: var(--mdrt-color-text-on-light-action);
    font: var(--mdrt-title-3);
    text-transform: lowercase;
  }

  @include mixins.breakpoint('medium') {
    flex-direction: row;
  }
}
