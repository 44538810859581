@use '@shared/util-styling/mixins';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-8);
  padding-top: var(--spacing-56);

  p {
    color: var(--mdrt-color-text-on-light-primary);
    font: var(--mdrt-body-3-long);
  }

  &__accordion_list {
    padding-top: var(--spacing-16);
  }

  &__label {
    font: var(--mdrt-headline-2);
    color: var(--mdrt-color-text-on-light-primary);
  }

  &__accordion {
    padding: var(--spacing-8) 0;

    &:not(:last-child) {
      border-bottom: 1px solid var(--mdrt-color-borders-dark-02);
    }
  }
}
