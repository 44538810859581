@use '@shared/util-styling/mixins';

.header-content-mobile {
  align-items: center;
  display: flex;
  gap: var(--spacing-20);
  justify-content: space-between;
  padding: var(--spacing-12) var(--spacing-16);
  top: 0;
  width: 100%;

  &__logo-container {
    height: 56px;
    max-width: 73px;
  }

  @include mixins.breakpoint('medium') {
    display: none;
  }
}

.language-button {
  @include mixins.menu-item;

  &__icon {
    @include mixins.breakpoint('medium') {
      width: 30px;
    }
  }
}
