@use '../tokens';
@use '../mixins/button-reset' as reset;

@mixin button-icon {
  @include reset.button-reset;

  align-items: center;
  background-color: tokens.$mdrt-icon-button-bg-fill;
  border-radius: tokens.$mdrt-icon-button-corner-radius;
  color: tokens.$mdrt-icon-button-icon-fill;
  display: flex;
  justify-content: center;
  padding: tokens.$mdrt-icon-button-padding;

  & > svg {
    height: tokens.$mdrt-icon-button-icon-size;
    width: tokens.$mdrt-icon-button-icon-size;
  }

  &:hover {
    background-color: tokens.$mdrt-icon-button-bg-hover;
  }

  &--selected {
    background: tokens.$mdrt-icon-button-selected-bg-fill;
    color: tokens.$mdrt-icon-button-selected-icon-fill;

    &:hover {
      background: tokens.$mdrt-icon-button-selected-bg-fill-hover;
      color: tokens.$mdrt-icon-button-selected-icon-fill;
    }
  }
}

@mixin button {
  $_this: &;

  align-items: center;
  appearance: none;
  background-color: tokens.$mdrt-buttons-primary-bg-fill;
  border: solid tokens.$mdrt-buttons-size-standard-border-thickness
    tokens.$mdrt-buttons-primary-border-fill;
  border-radius: tokens.$mdrt-buttons-size-standard-radius;
  color: tokens.$mdrt-buttons-primary-text-enabled;
  cursor: pointer;
  display: flex;
  font: var(--button-font);
  justify-content: center;
  padding: tokens.$mdrt-buttons-size-standard-vertical-padding
    tokens.$mdrt-buttons-size-standard-horizontal-padding;
  text-decoration: none;

  &__prefix {
    height: tokens.$mdrt-buttons-icon-size;
    margin-right: tokens.$mdrt-buttons-gap-between;
    width: tokens.$mdrt-buttons-icon-size;
  }

  &__suffix {
    height: tokens.$mdrt-buttons-icon-size;
    margin-left: tokens.$mdrt-buttons-gap-between;
    width: tokens.$mdrt-buttons-icon-size;
  }

  &:hover {
    background-color: tokens.$mdrt-buttons-primary-bg-hover;
    border-color: tokens.$mdrt-buttons-primary-border-hover;
    color: tokens.$mdrt-buttons-primary-text-hover;
    text-decoration: none;
  }

  &:disabled {
    background-color: tokens.$mdrt-buttons-primary-bg-disabled;
    border-color: tokens.$mdrt-buttons-primary-bg-disabled;
    color: tokens.$mdrt-buttons-primary-text-disabled;
    cursor: not-allowed;
  }

  &--secondary {
    background-color: tokens.$mdrt-buttons-secondary-bg-fill;
    border-color: tokens.$mdrt-buttons-secondary-border-fill;
    color: tokens.$mdrt-buttons-secondary-text-enabled;

    &:hover {
      background-color: tokens.$mdrt-buttons-secondary-bg-hover;
      border-color: tokens.$mdrt-buttons-secondary-border-hover;
    }

    &:disabled {
      background-color: tokens.$mdrt-buttons-secondary-bg-disabled;
      border-color: tokens.$mdrt-buttons-secondary-bg-disabled;
      color: tokens.$mdrt-buttons-secondary-text-disabled;
    }
  }

  &--tertiary {
    @include reset.button-reset;

    color: tokens.$mdrt-chips-action-chips-text-fill-high-emphasis;
    font: var(--button-font);
    padding: tokens.$mdrt-buttons-vertical-padding 0;
    position: relative;
    text-decoration: underline;
    text-decoration-color: tokens.$mdrt-chips-action-chips-bg-fill-high-emphasis;
    text-decoration-thickness: 2px;
    text-underline-offset: var(--spacing-8);
    width: fit-content;

    &:hover {
      background-color: tokens.$mdrt-chips-action-chips-bg-fill-high-emphasis;
      color: inherit;
    }
  }

  &--plain {
    @include reset.button-reset;

    &:hover {
      background: none;
      border: none;
      color: inherit;
      text-decoration: none;
    }
  }

  &--icon {
    @include button-icon;
  }

  &--inverted {
    &#{$_this}--tertiary {
      color: var(--mdrt-color-text-on-dark-primary);

      &:hover {
        color: tokens.$mdrt-chips-action-chips-text-fill-high-emphasis;
      }
    }
  }

  &--small {
    font: var(--mdrt-title-3);
    padding: tokens.$mdrt-buttons-size-small-vertical-padding
      tokens.$mdrt-buttons-size-small-horizontal-padding;

    &#{$_this}--tertiary {
      font: var(--mdrt-title-3);
    }
  }

  &--uppercase {
    text-transform: uppercase;
  }
}
