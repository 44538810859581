.avatar {
  border-radius: var(--mdrt-corner-radius-maximum);
  object-fit: cover;
  object-position: top;

  // workaround for https://stackoverflow.com/questions/74502978/object-fit-cover-gives-pixelated-images-on-chrome/75120770
  overflow-clip-margin: unset;

  outline: var(--mdrt-border-thickness-03) solid var(--mdrt-color-borders-light-01);

  &--size-tiny {
    outline: var(--mdrt-border-thickness-02) solid var(--mdrt-color-borders-light-01);
  }
}
