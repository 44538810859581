@use '@shared/util-styling/mixins';

.drawer-wrapper {
  border: none;
  height: 100%;
  inset: 0;
  overflow: hidden;
  position: fixed;
  width: 100vw;
  z-index: var(--z-index-modal);

  &::before {
    animation: fadeIn var(--animation-duration) ease-out;
    background-color: var(--temp-color-neutral-dark-transparent-40);
    content: '';
    height: 100%;
    position: absolute;
    width: 100%;
  }
}

.drawer {
  animation: slideIn var(--animation-duration) ease-out;
  background-color: var(--temp-color-neutral-light);
  box-shadow: -10px 0 10px var(--temp-color-neutral-dark-transparent-40);
  height: 100%;
  overflow: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 70vw;

  &__actions {
    padding: var(--spacing-16) var(--spacing-16) 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  &__close-button {
    color: var(--mdrt-color-backgrounds-bg-primary-fill-01);
    height: var(--mobile-drawer-actions-height);
    width: var(--mobile-drawer-actions-width);

    &:hover {
      color: var(--mdrt-color-backgrounds-bg-primary-fill-01);
    }

    svg {
      // NOTE: leave pointer-events: none because google analytics needs to track <button /> tag attributes but not the <svg />
      pointer-events: none;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    right: -100%;
  }

  to {
    right: 0;
  }
}

@include mixins.breakpoint('medium') {
  .drawer-wrapper {
    display: none;
  }

  .drawer {
    display: none;
  }
}
