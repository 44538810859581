@use '@shared/util-styling/mixins';

.header-wrapper {
  background-color: var(--mdrt-color-backgrounds-bg-surface);
  color: var(--mdrt-color-text-on-light-action);
  width: 100%;
  z-index: var(--z-index-navbar);

  &--secondary {
    background-color: var(--mdrt-color-backgrounds-bg-primary-fill-01);
    color: var(--mdrt-color-text-on-dark-primary);
  }

  @include mixins.breakpoint('medium') {
    position: sticky;
    top: 0;

    &--has-sub-menu {
      margin-bottom: var(--app-sub-menu-height);
    }
  }

  @media print {
    display: none;
  }
}

.header {
  align-items: center;
  display: flex;
  height: var(--app-header-height-primary);
  justify-content: center;

  @include mixins.breakpoint('medium') {
    height: var(--app-header-height-secondary);
  }
}

.loader-container {
  position: fixed;
  top: 0;
  width: 100%;

  @include mixins.breakpoint('medium') {
    bottom: 0;
    position: absolute;
    top: auto;
  }
}
