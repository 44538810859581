@mixin form-layout($footer-border: true, $form-top-border: false, $fieldset-gap: var(--spacing-8)) {
  $_border-style: var(--mdrt-border-thickness-01) solid var(--mdrt-color-borders-dark-02);

  @if $form-top-border {
    border-top: $_border-style;
    margin-top: var(--spacing-24);
    padding-top: var(--spacing-24);
  }

  display: flex;
  flex-direction: column;
  gap: var(--spacing-24);
  position: relative;

  &__heading {
    font: var(--mdrt-headline-4);
  }

  &__sub-heading {
    color: var(--mdrt-color-text-on-light-secondary);
    font: var(--mdrt-title-3);
    margin-bottom: var(--spacing-16);
  }

  &__content {
    font: var(--mdrt-body-3-long);

    &--reduce-spacing {
      margin-top: var(--spacing-16);
    }
  }

  &__fieldset {
    border: 0;
    display: flex;
    flex-direction: column;
    gap: $fieldset-gap;
    margin: var(--spacing-16) 0 0;
    padding: 0;
  }

  &__legend {
    font: var(--mdrt-body-3-long);
    margin-bottom: var(--spacing-16);
  }

  &__footer {
    @if $footer-border {
      border-top: $_border-style;
      padding-top: var(--spacing-24);
    }

    display: flex;
    justify-content: flex-end;
  }

  &__separator {
    background-color: var(--mdrt-color-borders-dark-02);
    border: 0;
    height: var(--mdrt-border-thickness-01);
    margin: 0;
    width: 100%;
  }
}
