@use '@shared/util-styling/mixins';

.toast-region {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-8);
  justify-content: center;
  left: 0;
  margin: var(--spacing-8) auto 0;
  max-width: var(--toast-max-width);
  position: fixed;
  right: 0;
  top: var(--app-header-height-primary);
  z-index: var(--z-index-toast);

  @include mixins.breakpoint('medium') {
    top: var(--app-header-height-secondary);
  }
}
